<template>
  <linkook-live-banner></linkook-live-banner>
  <linkook-live-tiles></linkook-live-tiles>
</template>
<script>
import LinkookLiveBanner from "@/components/LinkookLiveBanner.vue";
import LinkookLiveTiles from "@/components/LinkookLiveTiles.vue";

export default {
  name: "LinkookLiveView",
  components: {
    LinkookLiveBanner,
    LinkookLiveTiles,
  },
};
</script>
