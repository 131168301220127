<template>
  <div :class="$style.upload">
    <!-- <img
      :class="$style.logo"
      alt="Linkook logo"
      src="@/assets/images/logo_white.png"
    /> -->

    <!-- <h5>링꾹라이브 On! 등록하기</h5> -->
    <div :class="$style.inputs">
      <div :class="$style.input">
        <h4>링꾹 라이브 On! 등록하기</h4>
        <input
          :class="$style.textInput"
          v-model="link"
          placeholder="유튜브 영상 링크를 입력해주세요"
          :disabled="uploading"
        />
      </div>
      <div :class="$style.buttons">
        <button
          :class="$style.cancel"
          @click="cancelPopup"
          :disabled="uploading"
        >
          취소
        </button>
        <button :class="$style.register" @click="upload" :disabled="uploading">
          <span v-if="!uploading">등록</span>
          <span v-else> <i :class="$style.spinner"></i> </span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { firestore } from "/firebaseConfig";
import "@vuepic/vue-datepicker/dist/main.css";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default {
  name: "LinkookLiveUpload",
  components: {},
  data() {
    return {
      dateFormat: "yyyy-MM-dd HH:mm",
      selectedDateTime: new Date(),
      link: "",
      uploading: false,
    };
  },
  methods: {
    cancelPopup() {
      this.$emit("close-popup");
    },
    renew() {
      this.selectedDateTime = new Date();
      this.link = "";
      this.uploading = false;
    },
    toast(msg, type) {
      createToast(
        {
          title: msg,
          // description: 'Toast description'
        },
        {
          type: type, // 'info', 'danger', 'warning', 'success', 'default'
          timeout: 5000,
          showCloseButton: true,
          position: "top-right", // 'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'
          transition: "bounce",
          hideProgressBar: false,
          swipeClose: true,
          // toastBackgroundColor: '#fafafa',
          onClose: null,
        }
      );
    },
    async upload() {
      if (!this.checkUrl(this.link)) {
        this.toast("YOUTUBE 링크를 입력해주세요", "default");
        return;
      }

      if (this.link.includes("@")) {
        this.toast("채널주소는 등록할 수 없습니다", "danger");
        return;
      }

      const vid = this.extractYouTubeVideoId(this.link);
      if (!vid) {
        this.toast("유효한 유튜브 링크가 아닙니다", "danger");
        return;
      }

      try {
        this.uploading = true;

        const videoDocRef = doc(firestore, "videos", vid);
        const existingDoc = await getDoc(videoDocRef);

        if (existingDoc.exists()) {
          this.toast("이미 등록된 영상입니다", "warning");
          this.renew();
          return;
        }

        const vInfo = await this.fetchVideoDetails(vid);
        if (!vInfo) {
          this.toast("유튜브 연결에 실패했습니다.", "danger");
          this.renew();
          return;
        }

        // ✅ 커버 키워드 필터링
        const title = vInfo.title || "";
        const coverKeywords = [
          /cover/i,
          "커버",
          "불러보았다",
          "歌ってみた",
          "うたってみた",
        ];

        const isCover = coverKeywords.some((keyword) => {
          if (keyword instanceof RegExp) {
            return keyword.test(title);
          }
          return title.includes(keyword);
        });
        if (!isCover) {
          this.toast("커버송 영상만 등록할 수 있습니다", "warning");
          this.renew();
          return;
        }

        const videoData = {
          id: vid,
          title: vInfo.title,
          channel: vInfo.channel,
          link: this.link,
          date: vInfo.convertedPublishedAt,
          tweetLikes: 0,
          youtubeLikes: vInfo.likes,
          likes: 0, // 처음 등록이므로 0으로 시작
        };

        // ✅ 문서가 없을 때만 등록
        await setDoc(videoDocRef, videoData);

        this.cancelPopup();
        this.toast("링크 등록 완료!", "default");
        this.renew();
        this.$emit("upload-success");
      } catch (error) {
        this.toast("링크 등록에 실패했습니다", "danger");
        this.renew();
        console.error("Error uploading data:", error);
      }
    },
    // 유튜브 비디오 ID 추출 함수
    extractYouTubeVideoId(url) {
      let videoId = null;

      // 1️⃣ `youtu.be/VIDEO_ID` 형식인 경우
      if (url.includes("youtu.be/")) {
        videoId = url.split("youtu.be/")[1].split(/[?&]/)[0];
      }
      // 2️⃣ `youtube.com/watch?v=VIDEO_ID` 형식인 경우
      else if (url.includes("youtube.com/watch?v=")) {
        videoId = url.split("v=")[1].split(/[?&]/)[0];
      }
      return videoId;
    },
    async fetchVideoDetails(vid) {
      const url = `https://www.googleapis.com/youtube/v3/videos?part=snippet,statistics&id=${vid}&key=${process.env.VUE_APP_YOUTUBE_API_KEY}`;
      try {
        const response = await axios.get(url);
        const items = response.data.items;

        if (items.length > 0) {
          const snippet = items[0].snippet;
          const statistics = items[0].statistics;
          const likes = Number(statistics.likeCount);
          const title = snippet.title;
          const channel = snippet.channelTitle;
          const publishedAt = snippet.publishedAt;

          // console.log("📌 제목:", title);
          // console.log("📌 채널명:", channel);
          // console.log("📌 등록일 (UTC):", publishedAt);

          // UTC → KST 변환
          // console.log("📌 등록일 (KST):", this.convertToKST(publishedAt));
          const convertedPublishedAt = this.convertToKST(publishedAt);
          return { title, channel, convertedPublishedAt, likes };
        } else {
          alert("❌ 영상을 찾을 수 없습니다.");
          return null;
        }
      } catch (error) {
        alert(
          "❌ YOUTUBE API 요청 실패:",
          error.response?.data || error.message
        );
        return null;
      }
    },

    formatDate(date) {
      if (!(date instanceof Date) || isNaN(date)) {
        return "유효하지 않은 날짜";
      }
      // 날짜 구성
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 월 (0부터 시작)
      const day = String(date.getDate()).padStart(2, "0");

      // 시간 구성
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      // 원하는 형식으로 반환
      return `${year}.${month}.${day} ${hours}:${minutes}`;
    },
    checkUrl(url) {
      const httpsPattern = /^https:\/\/[^\s$.?#].[^\s]*$/i;
      return httpsPattern.test(url);
    },
    convertToKST(utcTime) {
      const date = new Date(utcTime);
      date.setHours(date.getHours() + 9); // UTC + 9시간 = 한국 시간
      return date.toISOString().replace("T", " ").replace("Z", "");
    },
  },
};
</script>
<style module>
.upload {
  /* overflow: auto; */
}
h5 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 5px;
}
h4 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 5px;
  margin-left: 0px;
  text-align: left;
}
input {
  width: 97%;
  font-size: 14px;
  height: 30px !important;
}
input[type="radio" i] {
  width: 12px;
  height: 12px !important;
}
.radio {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
label {
  padding-right: 30px;
}

.picker {
  width: 100%;
  font-size: 14px;
  height: 25px;
}

.textInput {
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 0px 5px;
}
.textInput:focus {
  border: 1px solid #aaaeb7;
}
.fileInput {
  border: none;
}
.fileInput {
  border: none;
}

.inputs {
  width: 95%;
  margin: auto;
}
.input {
  padding: 5px;
}
.logo {
  width: 70%;
}

.buttons {
  margin-top: 20px;
}

.cancel {
  width: 30%;
  padding: 15px;
  margin: 5px;
  border-radius: 5px;
  background-color: #cbcbcb;
  min-width: 60px;
}

.register {
  width: 30%;
  padding: 15px;
  margin: 5px;
  border-radius: 5px;
  background-color: rgb(102, 189, 255);
  min-width: 60px;
}

.spinner {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 2px solid transparent;
  border-top: 2px solid #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 8px;
  box-sizing: border-box;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
