<template>
  <div class="toggle" :class="{ 'toggle-on': onoff }" @click="toggle">
    <div class="knob"></div>
    <div class="text">{{ onoff ? "On" : "Off" }}</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      onoff: this.$route.path === "/on",
    };
  },
  watch: {
    // 2️⃣ 라우트 변경 감지 → onoff 상태 업데이트
    "$route.path"(newPath) {
      this.onoff = newPath === "/on";
    },
  },
  methods: {
    // 3️⃣ 버튼 클릭 시 URL의 `onoff` 값을 변경하여 상태 업데이트
    toggle() {
      const newPath = this.onoff ? "/off" : "/on";
      this.$router.push(newPath);
    },
  },
};
</script>

<style scoped>
/* 공통 스타일 */
.toggle {
  margin: auto;
  width: 80px;
  height: 44px;
  position: relative;
  border-radius: 1000px;
  background-color: #e0e0e0; /* Off 상태 배경색 */
  display: flex;
  align-items: center;
  text-align: right;
  padding: 0 4px;
  justify-content: space-between;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.text {
  font-size: 24px;
  font-family: "Source Sans Pro", sans-serif;
  font-weight: 600;
  color: #808080; /* Off 상태 텍스트 색상 */
  transition: color 0.3s ease;
  padding: 5px 7px;
  margin-top: 2px;
}

.knob {
  width: 34px;
  height: 34px;
  background: white;
  border-radius: 50%;
  box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

/* ON 상태 스타일 */
.toggle-on {
  background-color: #6bbeb8; /* On 상태 배경색 */
}

.toggle-on .text {
  color: white; /* On 상태 텍스트 색상 */
  transform: translateX(-40px); /* Knob을 오른쪽으로 이동 */
}

.toggle-on .knob {
  transform: translateX(45px); /* Knob을 른쪽으로 이동 */
}

@media screen and (max-width: 1000px) {
  .toggle {
    margin: auto;
    width: 64px;
    height: 34px;
    position: relative;
    border-radius: 1000px;
    background-color: #e0e0e0; /* Off 상태 배경색 */
    display: flex;
    align-items: center;
    text-align: right;
    padding: 0 4px;
    justify-content: space-between;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .text {
    font-size: 18px;
    font-family: "Source Sans Pro", sans-serif;
    font-weight: 600;
    color: #808080; /* Off 상태 텍스트 색상 */
    transition: color 0.3s ease;
    padding: 5px 7px;
    margin-top: 2px;
  }

  .knob {
    width: 28px;
    height: 28px;
    background: white;
    border-radius: 50%;
    box-shadow: 0px 3px 1px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease;
  }

  /* ON 상태 스타일 */
  .toggle-on {
    background-color: #6bbeb8; /* On 상태 배경색 */
  }

  .toggle-on .text {
    color: white; /* On 상태 텍스트 색상 */
    transform: translateX(-30px); /* Knob을 오른쪽으로 이동 */
  }

  .toggle-on .knob {
    transform: translateX(35px); /* Knob을 른쪽으로 이동 */
  }
}
</style>
