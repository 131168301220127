import { createRouter, createWebHistory } from "vue-router";
import LinkookLiveView from "../views/LinkookLiveView.vue";
import LinkookLiveOn from "../views/LinkookLiveOn.vue";
import LinkookLiveSignin from "../views/LinkookLiveSignin.vue";
import KakaoLogin from "../views/KakaoLogin.vue";

const routes = [
  {
    path: "/on",
    name: "LinkookOn",
    component: LinkookLiveOn,
  },
  {
    path: "/off",
    name: "LinkookOff",
    component: LinkookLiveView,
  },
  {
    path: "/signin",
    name: "LinkookSignin",
    component: LinkookLiveSignin,
  },
  {
    path: "/kakaoLogin",
    name: "KakaoLogin",
    component: KakaoLogin,
  },
  { path: "/:pathMatch(.*)*", redirect: "/off" },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
