<template>
  <div class="linkooklive">
    <linkook-live-header></linkook-live-header>
    <router-view />
    <linkook-live-footer></linkook-live-footer>
  </div>
</template>
<script>
import LinkookLiveHeader from "@/components/LinkookLiveHeader.vue";
import LinkookLiveFooter from "@/components/LinkookLiveFooter.vue";
import { ref, runTransaction } from "firebase/database";
import { rtdb } from "/firebaseConfig";

export default {
  name: "App",
  components: {
    LinkookLiveHeader,
    LinkookLiveFooter,
  },
  data() {
    return {
      activeConnections: 0,
      onoff: true,
    };
  },
  watch: {
    onoff(newVal) {
      if (newVal) {
        this.$router.push("/on");
      } else {
        this.$router.push("/off");
      }
    },
  },
  methods: {
    recordVisitor() {
      const today = new Date().toISOString().split("T")[0]; // YYYY-MM-DD 형식
      const dailyVisitorRef = ref(rtdb, `visitorCount/${today}/all`);
      runTransaction(dailyVisitorRef, (currentValue) => {
        return (currentValue || 0) + 1;
      });
    },
  },
  mounted() {
    // 컴포넌트가 마운트될 때 방문자 수 증가
    this.recordVisitor();
  },
};
</script>
<style scoped>
@import "./assets/global.css";
</style>
