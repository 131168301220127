<template>
  <div :class="$style.header">
    <div :class="$style.logoDiv">
      <!-- <p :class="$style.logoText">동인계 라이브 홍보 프로젝트</p> -->
      <img
        :class="$style.logo"
        alt="Linkook logo"
        src="@/assets/images/logo_white.png"
      />
    </div>
    <div :class="$style.buttons">
      <on-off-toggle :class="$style.toggle"></on-off-toggle>
    </div>
  </div>
</template>

<script>
import Swal from "sweetalert2";
import OnOffToggle from "@/components/OnOffToggle.vue";
export default {
  name: "LinkookLiveHeader",
  components: { OnOffToggle },
  props: {
    onoff: Boolean,
  },
  data() {
    return {};
  },
  created() {},
  methods: {
    infoClick() {
      Swal.fire({
        title: "CONTACT US",
        text: "링크만 꾹! 링꾹 라이브 linkookit@gmail.com",
        icon: "info", // 'success', 'error', 'warning', 'info', 'question'
        confirmButtonText: "OK",
      });
    },
  },
};
</script>
<style module>
.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: 1180px;
}
.buttons {
  display: flex;
  gap: 2px;
  /* margin-top: 20px; */
}
.button {
  border: 0px;
  background-color: transparent;
}
.headerIcon {
  width: 40px;
  padding-top: 20px;
}
.logoDiv {
  padding: 10px;
  padding-bottom: 5px;
}
.logoText {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  font-weight: bold;
  font-size: 18px;
  padding: 0px;
  color: #868686;
}
.logo {
  width: 400px;
}

@media screen and (max-width: 1200px) {
  .logo {
    width: 300px;
  }
  .logoText {
    font-size: 16px;
  }
}
@media screen and (max-width: 1200px) {
  .logo {
    width: 300px;
  }
  .logoText {
    font-size: 16px;
  }
}

@media screen and (max-width: 900px) {
  .logo {
    width: 200px;
  }
  .headerIcon {
    width: 30px;
  }
  .logoText {
    font-size: 14px;
  }
}

@media screen and (max-width: 900px) {
  .logo {
    width: 200px;
  }
  .headerIcon {
    width: 30px;
  }
  .logoText {
    font-size: 14px;
  }
}
</style>
