<template>
  <div :class="$style.searchBar">
    <div :class="$style.searchBox">
      <button @click="changeType(1)" :class="$style.allTypeButton">
        <span
          :style="
            type == 1
              ? 'background-color : #323232; color: #ffffff;'
              : 'background-color : #ffffff; color: #323232;'
          "
          >ALL</span
        >
      </button>
      <button @click="changeType(2)" :class="$style.vocalButon">
        <img
          :class="$style.typeIcon"
          loading="lazy"
          alt=""
          :src="type == 2 ? typeUrl('vocal-white') : typeUrl('vocal')"
          :style="
            type == 2
              ? 'background-color : #323232; color: #ffffff;'
              : 'background-color : #ffffff; color: #000000;'
          "
        />
      </button>

      <button @click="changeType(3)" :class="$style.bandButton">
        <img
          :class="$style.typeIcon"
          loading="lazy"
          alt=""
          :src="type == 3 ? typeUrl('band-white') : typeUrl('band')"
          :style="
            type == 3
              ? 'background-color : #323232; color: #ffffff;'
              : 'background-color : #ffffff; color: #000000;'
          "
        />
      </button>
      <input
        :class="$style.searchInput"
        v-model="searchQuery"
        type="text"
        placeholder="제목, 라인업 검색하기"
      />
      <div>
        <!-- <img
          :class="$style.searchIcon"
          alt=""
          src="@/assets/images/search.svg"
        /> -->
      </div>
    </div>
    <div :class="$style.buttons">
      <button @click="openPopup" :class="$style.uploadButton">
        <img
          :class="$style.plusIcon"
          loading="lazy"
          alt=""
          src="@/assets/images/plus.svg"
        />
      </button>
    </div>
  </div>
  <div :class="$style.tiles">
    <div
      :class="$style.tileItem"
      v-for="(live, index) in filteredItems"
      :key="index"
    >
      <a :href="live.link" target="_blank">
        <div :class="$style.imgDiv">
          <p
            :class="
              calculateDaysLeft(live.date) === '종료'
                ? $style.closed
                : $style.dayCount
            "
          >
            {{ calculateDaysLeft(live.date) }}
          </p>
          <!-- <p :class="$style.liveType">{{ live.type }}</p> -->
          <img
            :class="$style.liveType"
            loading="lazy"
            alt=""
            :src="typeUrl(live.type)"
          />
          <img loading="lazy" :alt="live.title" :src="live.img" />
        </div>
      </a>
      <div :class="$style.title">{{ live.title }}</div>
      <div :class="$style.title">{{ live.date }}</div>
      <!-- <img
              loading="lazy"
              :src="imageUrl"
            /> -->
    </div>
  </div>
  <!-- 어두운 배경 -->
  <div v-if="showPopup" :class="$style.overlay" @click="closePopup"></div>
  <!-- 팝업 -->
  <div v-if="showPopup" :class="$style.popup">
    <linkook-live-upload @close-popup="closePopup"></linkook-live-upload>
  </div>
</template>

<script>
import { collection, addDoc, getDocs } from "firebase/firestore";
import { ref, getDownloadURL } from "firebase/storage";
import { firestore, storage, rtdb } from "/firebaseConfig";
import {
  ref as dbRef,
  runTransaction as dbRunTransaction,
} from "firebase/database";
import { parse, isValid, isPast } from "date-fns";

import LinkookLiveUpload from "@/components/LinkookLiveUpload.vue";

export default {
  name: "LinkookLiveTiles",
  components: {
    LinkookLiveUpload,
  },
  computed: {
    filteredItems() {
      const query = this.searchQuery.toLowerCase();

      return this.lives
        .filter((item) => {
          // 검색어 조건 필터 (title과 lineup)
          const matchesQueryInTitle = item.title.toLowerCase().includes(query);
          const matchesQueryInLineup = item.lineup.some((lineupItem) =>
            lineupItem.toLowerCase().includes(query)
          );
          const matchesQuery = matchesQueryInTitle || matchesQueryInLineup;

          // 타입 조건 필터
          const matchesType =
            this.type === 1 ||
            (this.type === 2 && item.type === "vocal") ||
            (this.type === 3 && item.type === "band");

          return matchesQuery && matchesType;
        })
        .sort((a, b) => {
          // date-fns를 활용한 포맷팅 변환 (점(.)을 자동으로 처리)
          const parseDate = (dateStr) => {
            const tempDateStr = dateStr.replace(/\./g, "-").trim(); // 점(.)을 대시(-)로 변경
            const formattedDateStr = !tempDateStr.includes(":") // 시간이 없는 경우 기본값 " 00:00" 추가
              ? tempDateStr + " 00:00"
              : tempDateStr;

            return parse(formattedDateStr, "yyyy-MM-dd HH:mm", new Date());
          };

          const dateA = parseDate(a.date);
          const dateB = parseDate(b.date);

          // 날짜가 유효한지 확인 (Invalid Date 방지)
          if (!isValid(dateA) || !isValid(dateB)) {
            console.error("Invalid date detected:", a.date, b.date);
            return 0;
          }

          // 과거 여부 판별
          const isPastA = isPast(dateA);
          const isPastB = isPast(dateB);

          // 1. 과거와 미래 항목 분리 (미래는 앞으로)
          if (isPastA && !isPastB) return 1;
          if (!isPastA && isPastB) return -1;

          // 2. 같은 상태의 항목끼리 정렬
          return isPastA ? dateB - dateA : dateA - dateB;
        });
    },
  },
  created() {
    this.fetchLives();
    // this.fetchImg();
  },
  mounted() {
    this.recordVisitor();
  },
  data() {
    return {
      searchQuery: "",
      lives: [],
      // uploads: [
      //   {
      //     title: 'ONE OK LIVE',
      //     img: 'oneoklive.png',
      //     date: '2024.11.30 17:00',
      //     place: '홍대 롤러코스터홀',
      //     lineup: ['레드슈어','청린','KATE','M.I.A.VV','지라이밴도','TWO OK ROCK','제경','Ballantines'],
      //     link: 'https://x.com/1OKROCKLIVE',
      //     type: 'band'
      //   }
      imageUrl: "",
      showPopup: false, // 팝업 상태
      type: 1,
    };
  },
  methods: {
    recordVisitor() {
      const today = new Date().toISOString().split("T")[0]; // YYYY-MM-DD
      const path = `visitorCount/${today}/off`; // 'on' 또는 'off'
      const dailyPageRef = dbRef(rtdb, path);

      dbRunTransaction(dailyPageRef, (currentValue) => {
        return (currentValue || 0) + 1;
      });
    },
    changeType(typeNum) {
      this.type = typeNum;
    },
    calculateDaysLeft(targetDate) {
      const substrDate = targetDate.substring(0, 10);
      if (!substrDate) return "Invalid date";
      const now = new Date();
      const eventDate = parse(substrDate, "yyyy.MM.dd", new Date()); // 파싱
      if (isNaN(eventDate.getTime())) return "Invalid date";

      const nowMidnight = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()
      );
      const eventMidnight = new Date(
        eventDate.getFullYear(),
        eventDate.getMonth(),
        eventDate.getDate()
      );

      const daysLeft = Math.ceil(
        (eventMidnight - nowMidnight) / (1000 * 60 * 60 * 24)
      );
      return daysLeft === 0
        ? "D-DAY"
        : daysLeft >= 0
        ? `D-${daysLeft}`
        : `종료`;
    },
    async uploadToFirestore() {
      try {
        const collectionRef = collection(firestore, "lives"); // 'lives' collection 참조

        for (const item of this.uploads) {
          await addDoc(collectionRef, item); // Firestore에 개별 데이터 추가
        }

        console.log("All data uploaded successfully!");
      } catch (error) {
        console.error("Error uploading data:", error);
      }
    },
    async fetchLives() {
      try {
        const querySnapshot = await getDocs(collection(firestore, "lives")); // Firestore의 'lives' 컬렉션 가져오기

        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.approved) {
            const imageRef = ref(storage, `poster/${data.img}`);
            getDownloadURL(imageRef)
              .then((url) => {
                const transformedData = {
                  ...data,
                  img: url,
                };
                this.lives.push(transformedData);
              })
              .catch((err) => {
                console.error(err);
              });
          }
        });
      } catch (error) {
        console.error("Error fetching lives:", error);
      }
    },
    // async fetchImg() {
    //   // 이미지 URL 가져오기
    //   const imageRef = ref(storage, "oneoklive_banner.png");
    //   getDownloadURL(imageRef)
    //     .then((url) => {
    //       this.imageUrl = url;
    //       this.loading = false;
    //     })
    //     .catch((err) => {
    //       this.error = "이미지를 불러오는 데 실패했습니다.";
    //       this.loading = false;
    //       console.error(err);
    //     });
    // },
    openPopup() {
      this.showPopup = true;
      window.scrollTo(0, 0);
      document.body.classList.add("no-scroll");
    },
    closePopup() {
      this.showPopup = false;
      document.body.classList.remove("no-scroll");
    },
    typeUrl(type) {
      return require(`@/assets/images/${type}.png`);
    },
  },
};
</script>
<style module>
.buttons {
  display: flex;
}
.searchBar {
  width: 100%;
  display: flex;
  margin: auto;
  justify-content: space-between;
}
.searchInput {
  margin: 5px 2px;
  border: 1px solid black;
  border-radius: 20px;
  height: 30px;
  width: 400px;
  padding: 0px 10px;
  font-size: 17px;
}

.searchBox {
  display: inline-flex;
  background-color: var(--color-white);
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.searchIcon {
  width: 30px;
  padding-left: 5px;
  padding-top: 5px;
}
.uploadButton {
  display: block;
}
.plusIcon {
  width: 24px;
}
.allTypeButton > span {
  display: block;
  border-radius: 15px; /* 배경의 모서리를 둥글게 */
  line-height: 26px !important;
  width: 26px !important;
  border: 1px solid;
  margin-bottom: 2px;
  margin-right: 2px;
}
.typeIcon {
  padding: 5px; /* 배경과 글씨 사이의 여백 */
  margin: 0px 2px;
  border-radius: 15px; /* 배경의 모서리를 둥글게 */
  height: 15px !important;
  width: 15px !important;
  border: 1px solid;
}
.tiles {
  padding-top: 5px;
  padding-bottom: 10px;
  width: 100%;
  margin: auto;
  display: flex;
  flex-wrap: wrap; /* 줄바꿈 허용 */
  gap: 7px; /* 아이템 간격 */
  justify-content: start; /* 좌우 균등 분배 */
}
.tileItem {
  flex: 0 1 calc(33.333% - 5px); /* 3개씩 배치 */
  box-sizing: border-box; /* 패딩 포함 크기 계산 */
}
.tileItem img {
  width: 100%; /* 부모의 넓이에 맞게 확장 */
  height: auto; /* 비율 유지 */
  display: block; /* 블록 요소로 설정 */
}
.title {
  font-family: "Noto Sans KR", sans-serif;
  font-weight: bold;
  text-align: left;
  font-size: 20px;
}
.imgDiv {
  position: relative; /* 자식 요소를 배치하기 위해 relative 설정 */
  display: inline-block; /* 이미지를 감싸기 위한 적절한 레이아웃 */
}
.dayCount {
  position: absolute;
  top: 5px; /* 이미지 위에서의 위치 */
  right: 5px; /* 이미지 왼쪽에서의 위치 */
  color: black; /* 글자 색 */
  font-size: 0.8rem; /* 글자 크기 */
  font-weight: bold; /* 글자 두께 */
  background-color: rgba(255, 255, 255, 1); /* 반투명 배경 */
  padding: 0px 5px; /* 배경과 글씨 사이의 여백 */
  border-radius: 5px; /* 배경의 모서리를 둥글게 */
}
.liveType {
  position: absolute;
  bottom: 5px; /* 이미지 위에서의 위치 */
  left: 5px; /* 이미지 왼쪽에서의 위치 */
  color: black; /* 글자 색 */
  font-size: 0.8rem; /* 글자 크기 */
  font-weight: bold; /* 글자 두께 */
  background-color: rgba(255, 255, 255, 1); /* 반투명 배경 */
  padding: 0px 5px; /* 배경과 글씨 사이의 여백 */
  border-radius: 5px; /* 배경의 모서리를 둥글게 */
  height: 15px !important;
  width: 15px !important;
  padding: 2px;
}
.closed {
  position: absolute;
  top: 5px; /* 이미지 위에서의 위치 */
  right: 5px; /* 이미지 왼쪽에서의 위치 */
  color: grey; /* 글자 색 */
  font-size: 0.8rem; /* 글자 크기 */
  font-weight: bold; /* 글자 두께 */
  background-color: rgba(255, 255, 255, 1); /* 반투명 배경 */
  padding: 0px 5px; /* 배경과 글씨 사이의 여백 */
  border-radius: 5px; /* 배경의 모서리를 둥글게 */
}
a {
  padding: 0px;
}

/* 어두운 배경 */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* 어둡게 */
  z-index: 10;
}

/* 팝업 스타일 */
.popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 50%;
  height: 90%;
  max-height: 90%;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  z-index: 20;
  text-align: center;
  margin: auto;
  overflow-y: scroll;
}

button {
  padding: 0px;
  background-color: transparent;
  border: none;
}

@media screen and (max-width: 1200px) {
  .title {
    font-size: 17px;
  }
  .searchInput {
    height: 25px;
    width: 300px;
    font-size: 16px;
  }
  .searchIcon {
    width: 20px;
  }
  .popup {
    max-width: 70%;
    width: 70%;
  }
}

@media screen and (max-width: 1000px) {
  .title {
    font-size: 15px;
  }
  .searchInput {
    height: 25px;
    width: 300px;
    font-size: 14px;
  }
  .searchIcon {
    width: 20px;
  }
}

@media screen and (max-width: 800px) {
  .title {
    font-size: 14px;
  }
  .searchInput {
    height: 20px;
    width: 200px;
    font-size: 14px;
  }
  .popup {
    max-width: 80%;
    width: 80%;
  }
}
@media screen and (max-width: 700px) {
  .title {
    font-size: 12px;
  }
  .searchInput {
    font-size: 12px;
  }
  .popup {
    max-width: 90%;
    width: 90%;
  }
}
@media screen and (max-width: 540px) {
  .title {
    font-size: 10x;
  }
  .popup {
    max-width: 90%;
    width: 90%;
  }
  .searchInput {
    width: 150px;
  }
}
@media screen and (max-width: 430px) {
  .title {
    font-size: 9px;
  }
  .searchInput {
    width: 130px;
  }
}
@media screen and (max-width: 330px) {
  .title {
    font-size: 8px;
  }
  .searchInput {
    width: 100px;
  }
}
@media screen and (max-width: 290px) {
  .title {
    font-size: 7px;
  }
  .searchInput {
    width: 80px;
  }
}
</style>
