<template>
  <div :class="$style.upload">
    <img
      :class="$style.logo"
      alt="Linkook logo"
      src="@/assets/images/logo_white.png"
    />

    <h5>~ 라이브 등록신청서 ~</h5>
    <div :class="$style.inputs">
      <div :class="$style.input">
        <h4>라이브 제목</h4>
        <input
          :class="$style.textInput"
          v-model="title"
          placeholder="라이브 제목을 입력해주세요"
          :disabled="uploading"
        />
      </div>
      <div :class="$style.input">
        <h4>일정과 시간</h4>
        <VueDatePicker
          :class="$style.picker"
          v-model="selectedDateTime"
          locale="ko"
          time-picker-inline
          :format="dateFormat"
          :disabled="uploading"
        />
      </div>
      <div :class="$style.input">
        <h4>장소</h4>
        <input
          :class="$style.textInput"
          v-model="place"
          placeholder="라이브 장소를 입력해주세요"
          :disabled="uploading"
        />
      </div>
      <div :class="$style.input">
        <h4>공연타입</h4>
        <div :class="$style.radio">
          <label>
            <input
              type="radio"
              value="vocal"
              v-model="type"
              :disabled="uploading"
            />
            보컬
          </label>
          <label>
            <input
              type="radio"
              value="band"
              v-model="type"
              :disabled="uploading"
            />
            밴드
          </label>
          <label>
            <input
              type="radio"
              value="etc"
              v-model="type"
              :disabled="uploading"
            />
            기타
          </label>
        </div>
      </div>
      <div :class="$style.input">
        <h4>이동할 페이지</h4>
        <input
          :class="$style.textInput"
          v-model="link"
          placeholder="https://live.linkook.it"
          :disabled="uploading"
        />
      </div>
      <div :class="$style.input">
        <h4>라인업<span style="font-size: 12px"> </span></h4>
        <input
          :class="$style.textInput"
          v-model="member"
          placeholder="사람1, 사람2, 사람3"
          :disabled="uploading"
        />
      </div>
      <div :class="$style.input">
        <h4>포스터 <span style="font-size: 12px">(권장 : 2896x4096)</span></h4>
        <input
          :class="$style.fileInput"
          type="file"
          @change="handleFileUpload1"
          :disabled="uploading"
        />
      </div>
      <div :class="$style.input">
        <h4>
          배너
          <span style="font-size: 12px"
            >(권장 : 1454x714) 배너 광고 필요시 업로드, 별도 신청 가능</span
          >
        </h4>
        <input
          :class="$style.fileInput"
          type="file"
          @change="handleFileUpload2"
          :disabled="uploading"
        />
      </div>
      <div :class="$style.input">
        <h4>
          배너용 이미지 수정 신청
          <span style="font-size: 12px">
            기본 포스터가 임의로 수정됩니다. 배너용 파일 보유 시 '아니오'를
            선택해주세요.</span
          >
        </h4>
        <div :class="$style.radio">
          <label>
            <input
              type="radio"
              value="yes"
              v-model="bannercrop"
              :disabled="uploading"
            />
            네
          </label>
          <label>
            <input
              type="radio"
              value="no"
              v-model="bannercrop"
              :disabled="uploading"
            />
            아니오
          </label>
        </div>
      </div>
      <div :class="$style.buttons">
        <button
          :class="$style.cancel"
          @click="cancelPopup"
          :disabled="uploading"
        >
          취소
        </button>
        <button :class="$style.register" @click="upload" :disabled="uploading">
          <span v-if="!uploading">등록</span>
          <span v-else> <i :class="$style.spinner"></i> 업로드 중...</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { collection, addDoc } from "firebase/firestore";
import { firestore, storage } from "/firebaseConfig";
import { ref, uploadBytes } from "firebase/storage";
import VueDatePicker from "@vuepic/vue-datepicker";
import "@vuepic/vue-datepicker/dist/main.css";
import { createToast } from "mosha-vue-toastify";
import "mosha-vue-toastify/dist/style.css";

export default {
  name: "LinkookLiveUpload",
  components: {
    VueDatePicker,
  },
  data() {
    return {
      dateFormat: "yyyy-MM-dd HH:mm",
      selectedDateTime: new Date(),
      title: "",
      place: "",
      type: "",
      link: "",
      member: "",
      uploading: false,
      file1: null,
      file2: null,
      bannercrop: "",
    };
  },
  methods: {
    cancelPopup() {
      this.$emit("close-popup");
    },
    renew() {
      this.selectedDateTime = new Date();
      this.title = "";
      this.place = "";
      this.type = "";
      this.link = "";
      this.member = "";
      this.file1 = null;
      this.file2 = null;
      this.bannercrop = "";
      this.uploading = false;
    },
    handleFileUpload1(event) {
      this.file1 = event.target.files[0]; // 파일 선택
    },
    handleFileUpload2(event) {
      this.file2 = event.target.files[0]; // 파일 선택
    },
    toast(msg, type) {
      createToast(
        {
          title: msg,
          // description: 'Toast description'
        },
        {
          type: type, // 'info', 'danger', 'warning', 'success', 'default'
          timeout: 5000,
          showCloseButton: true,
          position: "top-right", // 'top-left', 'top-right', 'bottom-left', 'bottom-right', 'top-center', 'bottom-center'
          transition: "bounce",
          hideProgressBar: false,
          swipeClose: true,
          // toastBackgroundColor: '#fafafa',
          onClose: null,
        }
      );
    },
    async upload() {
      // createToast('Basic Toast.');
      // 1. 유효성 검사
      if (this.title.trim() == "") {
        this.toast("라이브 제목을 입력해주세요", "default");
        return;
      }
      if (this.place.trim() == "") {
        this.toast("라이브 장소를 입력해주세요", "default");
        return;
      }
      if (this.type.trim() == "") {
        this.toast("라이브 타입을 선택해주세요", "default");
        return;
      }
      if (this.link.trim() == "") {
        this.toast("이동할 링크를 입력해주세요", "default");
        return;
      }
      if (!this.checkUrl(this.link)) {
        this.toast("HTTPS링크를 입력해주세요", "default");
        return;
      }
      if (this.member.trim() == "") {
        this.toast("라인업을 입력해주세요", "default");
        return;
      }
      if (!this.member.includes(",")) {
        this.toast("라인업을 둘 이상 입력해주세요", "default");
        return;
      }
      if (!this.file1) {
        this.toast("포스터를 선택해주세요", "default");
        return;
      }
      // if (!this.file2){
      //   this.toast('배너이미지를 선택해주세요','default');
      //   return;
      // }
      if (!this.bannercrop) {
        this.toast("배너 이미지 신청 여부를 선택해주세요", "default");
        return;
      }

      const upload = {
        title: this.title,
        date: this.formatDate(this.selectedDateTime),
        place: this.place,
        link: this.link,
        lineup: this.member
          .trim()
          .split(",")
          .map((item) => item.trim()),
        type: this.type,
        img: this.file1.name,
        banner: this.file2 ? this.file2.name : "",
        bannercrop: this.bannercrop,
        approved: false,
      };

      try {
        this.uploading = true;

        const storageRef1 = ref(storage, `poster/${this.file1.name}`);
        await uploadBytes(storageRef1, this.file1);

        if (this.file2) {
          const storageRef2 = ref(storage, `banner/${this.file2.name}`);
          await uploadBytes(storageRef2, this.file2);
        }

        const collectionRef = collection(firestore, "lives"); // 'lives' collection 참조
        await addDoc(collectionRef, upload); // Firestore에 개별 데이터 추가

        this.cancelPopup();
        this.toast("업로드완료! 승인을 기다립니다", "default");
        console.log("All data uploaded successfully!");
        this.renew();
      } catch (error) {
        this.toast("에러가 발생했습니다", "danger");
        this.renew();
        console.error("Error uploading data:", error);
      }
    },
    formatDate(date) {
      if (!(date instanceof Date) || isNaN(date)) {
        return "유효하지 않은 날짜";
      }
      // 날짜 구성
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // 월 (0부터 시작)
      const day = String(date.getDate()).padStart(2, "0");

      // 시간 구성
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");

      // 원하는 형식으로 반환
      return `${year}.${month}.${day} ${hours}:${minutes}`;
    },
    checkUrl(url) {
      const httpsPattern = /^https:\/\/[^\s$.?#].[^\s]*$/i;
      return httpsPattern.test(url);
    },
  },
};
</script>
<style module>
.upload {
  /* overflow: auto; */
}
h5 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 5px;
}
h4 {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  margin: 5px;
  margin-left: 0px;
  text-align: left;
}
input {
  width: 97%;
  font-size: 14px;
  height: 30px !important;
}
input[type="radio" i] {
  width: 12px;
  height: 12px !important;
}
.radio {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}
label {
  padding-right: 30px;
}

.picker {
  width: 100%;
  font-size: 14px;
  height: 25px;
}

.textInput {
  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 0px 5px;
}
.textInput:focus {
  border: 1px solid #aaaeb7;
}
.fileInput {
  border: none;
}
.fileInput {
  border: none;
}

.inputs {
  width: 95%;
  margin: auto;
}
.input {
  padding: 5px;
}
.logo {
  width: 70%;
}

.buttons {
  margin-top: 20px;
}

.cancel {
  width: 30%;
  padding: 15px;
  margin: 5px;
  border-radius: 5px;
  background-color: #cbcbcb;
}

.register {
  width: 30%;
  padding: 15px;
  margin: 5px;
  border-radius: 5px;
  background-color: rgb(102, 189, 255);
}

.spinner {
  display: inline-block;
  width: 15px;
  height: 15px;
  border: 2px solid transparent;
  border-top: 2px solid #fff;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-right: 8px;
  box-sizing: border-box;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>
