<template>
  <div :class="$style.bannerContainer">
    <swiper
      :modules="[Navigation, Pagination, Autoplay]"
      navigation
      pagination
      loop
      :autoplay="{ delay: 3000, disableOnInteraction: false }"
    >
      <swiper-slide
        v-for="(banner, index) in banners"
        :key="index"
        :class="$style.slide"
      >
        <p :class="$style.dayCount">{{ banner.dayCount }}</p>
        <a :href="banner.link" target="_blank">
          <img
            :class="$style.bannerImg"
            :src="banner.img"
            :alt="`Banner ${index + 1}`"
          />
        </a>
        <p :class="$style.liveDate">{{ banner.date }}</p>
        <p :class="$style.livePlace">{{ banner.place }}</p>
      </swiper-slide>
    </swiper>
  </div>
</template>

<script>
import { parse } from "date-fns";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper-bundle.css";
import { Navigation, Pagination, Autoplay } from "swiper";

export default {
  name: "LinkookLiveBanner",
  components: {
    Swiper,
    SwiperSlide,
  },
  computed: {
    dayCount() {
      return this.calculateDaysLeft(this.bannerLive.date);
    },
  },
  data() {
    return {
      Navigation: Navigation,
      Pagination: Pagination,
      Autoplay: Autoplay,
      banners: [
        {
          title: "라이브슬롯",
          img: require("@/assets/images/livslot_crop.png"),
          date: "2025.02.22 16:00",
          place: "신촌 몽향홀",
          link: "https://x.com/Live__Slot",
          type: "vocal",
          dayCount: this.calculateDaysLeft("2025.02.22 16:00"),
        },
        {
          title: "夜行, 月明り",
          img: require("@/assets/images/yakou_crop.png"),
          date: "2025.02.22 18:00",
          place: "클럽 샤프",
          link: "https://x.com/E_E_neje/status/1886375780541993126",
          type: "band",
          dayCount: this.calculateDaysLeft("2025.02.22 18:00"),
        },
      ],
    };
  },
  methods: {
    calculateDaysLeft(targetDate) {
      const substrDate = targetDate.substring(0, 10);
      if (!substrDate) return "Invalid date";
      const now = new Date();
      const eventDate = parse(substrDate, "yyyy.MM.dd", new Date()); // 파싱
      if (isNaN(eventDate.getTime())) return "Invalid date";

      const nowMidnight = new Date(
        now.getFullYear(),
        now.getMonth(),
        now.getDate()
      );
      const eventMidnight = new Date(
        eventDate.getFullYear(),
        eventDate.getMonth(),
        eventDate.getDate()
      );

      const daysLeft = Math.ceil(
        (eventMidnight - nowMidnight) / (1000 * 60 * 60 * 24)
      );
      return daysLeft === 0
        ? "D-DAY"
        : daysLeft >= 0
        ? `D-${daysLeft}`
        : `종료`;
    },
  },
};
</script>
<style module>
.banner {
  width: 100%;
  position: relative; /* 자식 요소를 배치하기 위해 relative 설정 */
  display: inline-block; /* 이미지를 감싸기 위한 적절한 레이아웃 */
}
.bannerImg {
  width: 100%;
}
.dayCount {
  position: absolute;
  top: 5px; /* 이미지 위에서의 위치 */
  left: 5px; /* 이미지 왼쪽에서의 위치 */
  color: white; /* 글자 색 */
  font-size: 1.5rem; /* 글자 크기 */
  font-weight: bold; /* 글자 두께 */
  background-color: rgba(0, 0, 0, 0.5); /* 반투명 배경 */
  padding: 5px 10px; /* 배경과 글씨 사이의 여백 */
  border-radius: 5px; /* 배경의 모서리를 둥글게 */
}
.liveDate {
  position: absolute;
  bottom: 10px; /* 이미지 위에서의 위치 */
  right: 5px; /* 이미지 왼쪽에서의 위치 */
  color: white; /* 글자 색 */
  font-size: 1rem; /* 글자 크기 */
  font-weight: bold; /* 글자 두께 */
  background-color: rgba(0, 0, 0, 0.5); /* 반투명 배경 */
  padding: 5px 10px; /* 배경과 글씨 사이의 여백 */
  border-radius: 5px; /* 배경의 모서리를 둥글게 */
}
.livePlace {
  position: absolute;
  bottom: 45px; /* 이미지 위에서의 위치 */
  right: 5px; /* 이미지 왼쪽에서의 위치 */
  color: white; /* 글자 색 */
  font-size: 1rem; /* 글자 크기 */
  font-weight: bold; /* 글자 두께 */
  background-color: rgba(0, 0, 0, 0.5); /* 반투명 배경 */
  padding: 5px 10px; /* 배경과 글씨 사이의 여백 */
  border-radius: 5px; /* 배경의 모서리를 둥글게 */
}

@media screen and (max-width: 800px) {
  .liveDate {
    font-size: 0.9rem;
    padding: 5px;
    display: none;
  }
  .livePlace {
    font-size: 0.9rem;
    padding: 5px;
    display: none;
  }
}

@media screen and (max-width: 330px) {
  .liveDate {
    font-size: 0.7rem;
    padding: 3px 5px; /* 배경과 글씨 사이의 여백 */
    display: none;
  }
  .livePlace {
    bottom: 32px; /* 이미지 위에서의 위치 */
    padding: 3px 5px; /* 배경과 글씨 사이의 여백 */
    font-size: 0.7rem;
    display: none;
  }
}
</style>
