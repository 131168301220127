<template>
  <div>로그인 중입니다...</div>
</template>

<script>
import axios from "axios";
import { useUserStore } from "../store/user";
import { getFirestore, doc, setDoc } from "firebase/firestore";

export default {
  name: "KakaoLogin",
  components: {},
  data() {
    return {
      userStore: useUserStore(),
    };
  },
  created() {
    const urlParams = new URLSearchParams(window.location.search);
    // code가 있는 경우 토큰 발급 요청
    if (urlParams.has("code")) {
      const code = urlParams.get("code");
      this.setKakaoToken(code);
    }
  },
  methods: {
    // 1. 인가 코드 얻기
    // https://developers.kakao.com/docs/latest/ko/kakaologin/js#login
    kakaoLogin() {
      window.Kakao.Auth.authorize({
        redirectUri: "https://live.linkook.it/kakaoLogin",
      });
    },
    // 2. 토큰 조회
    // https://developers.kakao.com/docs/latest/ko/kakaologin/rest-api#request-token
    async setKakaoToken(code) {
      console.log("setKakaoToken");
      const { data } = await this.getKakaoToken(code);
      if (data.error) {
        console.log(data.error);
        return;
      }
      window.Kakao.Auth.setAccessToken(data.access_token);
      await this.setUserInfo();
    },
    // 3. 사용자 정보 조회
    // https://developers.kakao.com/docs/latest/ko/kakaologin/js#req-user-info
    async setUserInfo() {
      const res = await this.getKakaoUserInfo();
      const userInfo = res.kakao_account;

      console.log(userInfo);

      if (!userInfo?.email) {
        console.error("잘못된 유저 정보입니다.", userInfo);

        alert("로그인에 실패했습니다. 메인으로 돌아갑니다.");
      } else {
        //pinia 저장 시 local storage 에 자동 저장됨!
        this.userStore.setUserData(userInfo);

        // ✅ Firebase에 해당 유저 정보 저장
        try {
          const db = getFirestore();
          const userRef = doc(db, "users", userInfo.email); // 이메일을 문서 ID로 사용
          await setDoc(
            userRef,
            {
              email: userInfo.email,
              lastLogin: new Date(),
            },
            { merge: true }
          ); // 기존 데이터 유지하며 병합
        } catch (error) {
          console.error("Firebase 유저 정보 저장 실패:", error);
        }
      }

      this.$router.push({ path: "/on" });
    },
    async getKakaoToken(code) {
      try {
        const data = {
          grant_type: "authorization_code",
          client_id: "b9fbcd521f83d7d672067a9d8abf5781",
          redirect_uri: "https://live.linkook.it/kakaoLogin",
          code: code,
        };

        const queryString = Object.keys(data)
          .map((k) => encodeURIComponent(k) + "=" + encodeURIComponent(data[k]))
          .join("&");

        const result = await axios.post(
          "https://kauth.kakao.com/oauth/token",
          queryString,
          {
            headers: {
              "Content-type": "application/x-www-form-urlencoded;charset=utf-8",
            },
          }
        );
        return result;
      } catch (e) {
        alert(
          `카카오 토큰을 받아오는데 실패했습니다. 잠시 후 다시 시도해주세요. 오류코드 : [${e.response.data.error_code}]`
        );
        console.log("debug ", e);
        return e;
      }
    },
    async getKakaoUserInfo() {
      let data = "";
      await window.Kakao.API.request({
        url: "/v2/user/me",
      })
        .then(function (response) {
          data = response;
        })
        .catch(function (error) {
          console.log(error);
        });
      return data;
    },
    // 로그아웃
    kakaoLogout() {
      const { logOut } = useUserStore();

      window.Kakao.Auth.logout()
        .then(function (response) {
          logOut();
          console.log(window.Kakao.Auth.getAccessToken()); // null
          console.log(response);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>
