<template>
  <linkook-live-signin-content></linkook-live-signin-content>
</template>
<script>
import LinkookLiveSigninContent from "@/components/LinkookLiveSigninContent.vue";

export default {
  name: "LinkookLiveSignin",
  components: {
    LinkookLiveSigninContent,
  },
};
</script>
