<template>
  <div class="searchBar">
    <!-- 검색바 코드는 그대로 유지 -->
    <div class="searchBox">
      <button @click="changeType(1)" class="allTypeButton">
        <span>최신순</span>
      </button>
      <button @click="changeType(2)" class="allTypeButton">
        <span>인기순</span>
      </button>
      <button @click="changeType(3)" class="bookmark">
        <img
          src="@/assets/images/pink-heart_1fa77.png"
          alt="Likes"
          class="likes-icon"
        />
      </button>
      <input
        class="searchInput"
        v-model="searchQuery"
        type="text"
        placeholder="검색하기"
      />
      <div>
        <!-- <img class="searchIcon" alt="" src="@/assets/images/search.svg" /> -->
      </div>
    </div>
    <div class="buttons">
      <button @click="openPopup" class="uploadButton">
        <img
          class="plusIcon"
          loading="lazy"
          alt=""
          src="@/assets/images/plus.svg"
        />
      </button>
      <button v-if="!userStore.isLogin" @click="signIn" class="allTypeButton">
        <span>로그인</span>
      </button>
      <button v-else @click="userStore.logOut" class="allTypeButton">
        <span>로그아웃</span>
      </button>
    </div>
  </div>
  <div class="container">
    <div class="video-grid">
      <!-- 지연 로딩 및 썸네일 최적화 적용 -->
      <div v-for="video in paginatedItems" :key="video.id" class="video-tile">
        <div class="video-container">
          <!-- 썸네일 방식으로 변경하고 클릭 시 iframe 로드 -->
          <div
            v-if="!loadedVideos[video.id]"
            class="thumbnail-container"
            @click="loadVideo(video.id)"
          >
            <img
              :src="`https://img.youtube.com/vi/${video.id}/mqdefault.jpg`"
              alt="Video thumbnail"
              class="video-thumbnail"
              loading="lazy"
            />
            <div class="play-button">▶</div>
          </div>
          <iframe
            v-else
            :src="`https://www.youtube-nocookie.com/embed/${video.id}?autoplay=1&mute=0`"
            frameborder="0"
            allowfullscreen
            loading="lazy"
          ></iframe>
        </div>
        <div class="video-info">
          <div class="video-details">
            <p class="video-title">{{ video.title }}</p>
            <p class="video-channel">{{ video.channel }}</p>
            <p class="video-date">
              {{ video.date ? video.date.substring(0, 10) : "" }}
            </p>
          </div>
          <div class="video-actions">
            <p class="video-likes">
              <img
                src="@/assets/images/pink-heart_1fa77.png"
                alt="Likes"
                class="likes-icon"
              />
              <span class="likes-text">{{ getTotalLikes(video) }}</span>
            </p>
            <button
              :class="['like-button', { liked: video.liked }]"
              @click="likeVideo(video.id)"
            >
              {{ video.liked ? "Liked" : "Like" }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- 인터섹션 옵저버 기반 무한 스크롤 구현 -->
    <div
      v-if="paginatedItems.length > 0"
      ref="loadMoreTrigger"
      class="load-more-trigger"
    ></div>
    <!-- 기존 페이지네이션 -->
    <div class="pagination">
      <button
        :disabled="currentPage === 1"
        @click="currentPage--"
        class="page-button"
      >
        ◀ 이전
      </button>

      <button
        v-for="(page, index) in pageButtons"
        :key="index"
        class="page-button"
        :class="{ active: currentPage === page }"
        @click="typeof page === 'number' && (currentPage = page)"
        :disabled="page === '...'"
      >
        {{ page }}
      </button>

      <button
        :disabled="currentPage === totalPages"
        @click="currentPage++"
        class="page-button"
      >
        다음 ▶
      </button>
    </div>
  </div>
  <!-- 어두운 배경 -->
  <div v-if="showPopup" class="overlay" @click="closePopup"></div>
  <!-- 팝업 -->
  <div v-if="showPopup" class="popup">
    <linkook-live-youtube
      @close-popup="closePopup"
      @upload-success="onUploadSuccess"
    ></linkook-live-youtube>
  </div>
</template>

<script>
import { useUserStore } from "@/store/user";
import { rtdb } from "/firebaseConfig";
import {
  ref as dbRef,
  runTransaction as dbRunTransaction,
} from "firebase/database";
import LinkookLiveYoutube from "@/components/LinkookLiveYoutube.vue";
import {
  getFirestore,
  collection,
  getDocs,
  runTransaction,
  doc,
  getDoc,
  increment,
  arrayUnion,
  arrayRemove,
  query,
  orderBy,
  limit,
  startAfter,
} from "firebase/firestore";

export default {
  components: {
    LinkookLiveYoutube,
  },
  data() {
    return {
      videos: [],
      filteredVideos: [], // 정렬 및 필터링된 비디오 캐시
      likedVideos: [],
      searchQuery: "",
      type: 1,
      userStore: useUserStore(),
      showBookmark: false,
      showPopup: false, // 팝업 상태
      currentPage: 1,
      itemsPerPage: 12, // 페이지당 표시할 비디오 수
      loadedVideos: {}, // 로드된 비디오 추적
      isIntersectionObserverEnabled: true, // 무한 스크롤 상태
      isLoading: false, // 로딩 상태 추적
      lastDoc: null, // 파이어베이스 페이지네이션을 위한 마지막 문서
      hasMoreVideos: true, // 더 많은 비디오가 있는지 여부
    };
  },
  created() {
    this.getVideos();
    this.fetchLikedVideos();
  },
  mounted() {
    this.recordVisitor();
    this.setupIntersectionObserver();
  },
  beforeUnmount() {
    // 인터섹션 옵저버 정리
    if (this.observer) {
      this.observer.disconnect();
    }
  },
  computed: {
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      const end = start + this.itemsPerPage;
      return this.filteredItems.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.filteredItems.length / this.itemsPerPage);
    },

    pageButtons() {
      const pages = [];
      const total = this.totalPages;

      if (total <= 7) {
        for (let i = 1; i <= total; i++) pages.push(i);
      } else {
        pages.push(1); // 첫 페이지

        if (this.currentPage > 4) pages.push("...");

        const start = Math.max(2, this.currentPage - 1);
        const end = Math.min(total - 1, this.currentPage + 1);
        for (let i = start; i <= end; i++) pages.push(i);

        if (this.currentPage < total - 3) pages.push("...");

        pages.push(total); // 마지막 페이지
      }

      return pages;
    },
    filteredItems() {
      const query = this.searchQuery.toLowerCase();

      // 필터링
      let filtered = this.videos.filter((item) => {
        if (!query) return true;

        let matchesQueryInTitle = false;
        let matchesQueryInLineup = false;

        if (item.title) {
          matchesQueryInTitle = item.title.toLowerCase().includes(query);
        }
        if (item.channel) {
          matchesQueryInLineup = item.channel.toLowerCase().includes(query);
        }
        return matchesQueryInTitle || matchesQueryInLineup;
      });

      // 정렬
      if (this.type === 1) {
        filtered.sort((a, b) => new Date(b.date) - new Date(a.date));
      } else if (this.type === 2) {
        filtered.sort((a, b) => this.getTotalLikes(b) - this.getTotalLikes(a));
      } else if (this.type === 3) {
        filtered = this.videos.filter((item) =>
          this.likedVideos.includes(item.id)
        );
      }

      return filtered;
    },
  },
  watch: {
    "userStore.isLogin"(newVal) {
      if (newVal) {
        this.fetchLikedVideos();
      } else {
        this.videos = this.videos.map((video) => ({
          ...video,
          liked: false,
        }));
        this.likedVideos = [];
      }
    },
    type() {
      this.currentPage = 1;
      this.filteredVideos = []; // 캐시 초기화
    },
    searchQuery() {
      this.currentPage = 1;
      this.filteredVideos = []; // 캐시 초기화
    },
  },
  methods: {
    // 전체 좋아요 수 계산을 위한 헬퍼 함수
    getTotalLikes(video) {
      return (
        (video.likes || 0) + (video.tweetLikes || 0) + (video.youtubeLikes || 0)
      );
    },

    // 비디오 클릭 시 실제 iframe 로드
    loadVideo(videoId) {
      this.loadedVideos = { ...this.loadedVideos, [videoId]: true };
    },

    // 인터섹션 옵저버 설정
    setupIntersectionObserver() {
      if (!("IntersectionObserver" in window)) {
        this.isIntersectionObserverEnabled = false;
        return;
      }

      this.observer = new IntersectionObserver(
        (entries) => {
          const entry = entries[0];
          if (entry.isIntersecting && !this.isLoading && this.hasMoreVideos) {
            this.loadMore();
          }
        },
        {
          rootMargin: "100px",
          threshold: 0.1,
        }
      );

      if (this.$refs.loadMoreTrigger) {
        this.observer.observe(this.$refs.loadMoreTrigger);
      }
    },

    // 무한 스크롤을 위한 추가 비디오 로드
    async loadMore() {
      if (this.isLoading || !this.hasMoreVideos) return;

      this.isLoading = true;

      try {
        // Firebase 쿼리 로직은 getVideos 메서드에서 처리
        if (this.currentPage < this.totalPages) {
          this.currentPage++;
        }
      } catch (error) {
        console.error("Error loading more videos:", error);
      } finally {
        this.isLoading = false;
      }
    },

    recordVisitor() {
      const today = new Date().toISOString().split("T")[0]; // YYYY-MM-DD
      const path = `visitorCount/${today}/on`; // 'on' 또는 'off'
      const dailyPageRef = dbRef(rtdb, path);

      dbRunTransaction(dailyPageRef, (currentValue) => {
        return (currentValue || 0) + 1;
      });
    },
    changeType(typeNum) {
      this.type = typeNum;
    },
    onUploadSuccess() {
      this.getVideos(); // ✅ 리스트 새로고침
    },
    likeVideo(vid) {
      if (!this.userStore.isLogin) {
        alert("로그인이 필요한 서비스입니다.");
        return;
      }
      this.likeVideoSafely(this.userStore.email, vid);
    },
    async likeVideoSafely(userId, videoId) {
      const db = getFirestore();
      const userRef = doc(db, "users", userId);
      const videoRef = doc(db, "videos", videoId);

      try {
        await runTransaction(db, async (transaction) => {
          const userSnap = await transaction.get(userRef);
          const videoSnap = await transaction.get(videoRef);

          if (!userSnap.exists() || !videoSnap.exists()) {
            throw new Error("유저 또는 비디오 문서가 존재하지 않습니다.");
          }

          const userData = userSnap.data();
          const likedVideos = userData.likes || [];

          const targetVideo = this.videos.find((video) => video.id === videoId);

          if (likedVideos.includes(videoId)) {
            // 좋아요 취소
            transaction.update(videoRef, {
              likes: increment(-1),
            });

            transaction.update(userRef, {
              likes: arrayRemove(videoId),
            });

            if (targetVideo) {
              targetVideo.likes--;
              targetVideo.liked = false;
            }
            return;
          }
          transaction.update(videoRef, {
            likes: increment(1),
          });

          transaction.update(userRef, {
            likes: arrayUnion(videoId),
          });

          if (targetVideo) {
            targetVideo.likes++;
            targetVideo.liked = true;
          }
          console.log("좋아요가 성공적으로 추가되었습니다.");
        });
      } catch (error) {
        console.error("좋아요 처리 중 오류 발생:", error);
      }
    },
    async getVideos() {
      if (this.isLoading) return;
      this.isLoading = true;

      try {
        const db = getFirestore();
        const videosRef = collection(db, "videos");

        // 페이지네이션을 위한 쿼리 구성
        let videoQuery;

        if (!this.lastDoc) {
          // 첫 페이지 로드
          videoQuery = query(
            videosRef,
            orderBy("date", "desc"),
            limit(50) // 한 번에 더 많은 비디오 가져오기
          );
        } else if (this.hasMoreVideos) {
          // 추가 페이지 로드
          videoQuery = query(
            videosRef,
            orderBy("date", "desc"),
            startAfter(this.lastDoc),
            limit(50)
          );
        } else {
          this.isLoading = false;
          return;
        }

        const querySnapshot = await getDocs(videoQuery);

        // 결과가 비어있으면 더 이상 비디오가 없음
        if (querySnapshot.empty) {
          this.hasMoreVideos = false;
          this.isLoading = false;
          return;
        }

        // 마지막 문서 저장
        this.lastDoc = querySnapshot.docs[querySnapshot.docs.length - 1];

        const newVideos = querySnapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .filter((video) => {
            // ✅ 필수 필드가 존재하고 공백이 아닌 경우만 통과
            return (
              video.title &&
              video.title.trim() !== "" &&
              video.channel &&
              video.channel.trim() !== "" &&
              video.date &&
              !isNaN(new Date(video.date)) // 유효한 날짜인지 체크
            );
          });

        if (this.videos.length === 0) {
          this.videos = newVideos;
        } else {
          // 중복 비디오 방지
          const existingIds = new Set(this.videos.map((v) => v.id));
          const uniqueNewVideos = newVideos.filter(
            (v) => !existingIds.has(v.id)
          );
          this.videos = [...this.videos, ...uniqueNewVideos];
        }

        // 좋아요 상태 업데이트
        if (this.userStore.isLogin && this.likedVideos.length > 0) {
          this.videos = this.videos.map((video) => ({
            ...video,
            liked: this.likedVideos.includes(video.id),
          }));
        }

        // 필터링된 비디오 캐시 초기화
        this.filteredVideos = [];
      } catch (error) {
        console.error("Error fetching videos:", error);
      } finally {
        this.isLoading = false;
      }
    },
    async fetchLikedVideos() {
      this.likedVideos = [];
      if (!this.userStore.isLogin) {
        console.warn("로그인이 필요한 서비스입니다.");
        return;
      }
      try {
        const db = getFirestore();
        const userRef = doc(db, "users", this.userStore.email);
        const userSnap = await getDoc(userRef);

        if (userSnap.exists()) {
          this.likedVideos = userSnap.data().likes || [];
          // ✅ this.videos에 liked 상태 추가
          this.videos = this.videos.map((video) => ({
            ...video,
            liked: this.likedVideos.includes(video.id),
          }));
        } else {
          console.warn("유저 문서가 없습니다.");
        }
      } catch (error) {
        console.error("likedVideos 불러오기 실패:", error);
      }
    },
    signIn() {
      this.$router.push("/signin");
    },
    openPopup() {
      this.showPopup = true;
      window.scrollTo(0, 0);
      document.body.classList.add("no-scroll");
    },
    closePopup() {
      this.showPopup = false;
      document.body.classList.remove("no-scroll");
    },
  },
};
</script>

<style scoped>
/* 기존 스타일 유지 */
.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px 0px;
}
.video-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin: auto;
  max-width: 1200px;
}
.video-tile {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* 썸네일 관련 스타일 추가 */
.video-container {
  position: relative;
  width: 100%;
  height: 200px;
  border-radius: 10px;
  overflow: hidden;
}

.thumbnail-container {
  position: relative;
  width: 100%;
  height: 100%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
}

.video-thumbnail {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.2s;
}

.thumbnail-container:hover .video-thumbnail {
  opacity: 0.7;
}

.play-button {
  position: absolute;
  color: white;
  font-size: 48px;
  opacity: 0.8;
  transition: opacity 0.2s, transform 0.2s;
}

.thumbnail-container:hover .play-button {
  opacity: 1;
  transform: scale(1.2);
}

.video-tile iframe {
  width: 100%;
  height: 100%;
  border-radius: 10px;
}

/* 로딩 트리거 스타일 */
.load-more-trigger {
  height: 20px;
  margin: 20px 0;
}

/* 기존 스타일 유지 */
.video-info {
  width: 100%;
  padding: 5px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}
.video-details {
  flex-grow: 1;
  text-align: left;
}
.video-title {
  font-size: 14px;
  font-weight: bold;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  width: 17vw;
}
.video-channel,
.video-date {
  font-size: 12px;
  color: #666;
}
.video-actions {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
.video-likes {
  font-size: 12px;
  margin-bottom: 2px;
  display: flex;
  gap: 2px;
  padding: 5px;
}
.like-button {
  padding: 5px 10px;
  font-size: 12px;
  cursor: pointer;
  background-color: #ff4500;
  color: white;
  border-radius: 5px;
  border: 1px solid #fff;
}
.like-button:hover {
  background-color: #e03e00;
}

.like-icon {
  width: 20px;
  height: 20px;
}
.likes-icon {
  width: 16px;
  height: 16px;
}
.likes-text {
  padding-left: 1px;
  padding-bottom: 2px;
}
.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
}
.searchBar {
  width: 98%;
  display: flex;
  margin: auto;
  justify-content: space-between;
  max-width: 1180px;
}
.searchInput {
  margin: 5px 2px;
  border: 1px solid black;
  border-radius: 20px;
  height: 30px;
  width: 400px;
  padding: 0px 10px;
  font-size: 17px;
}

.searchBox {
  display: inline-flex;
  background-color: var(--color-white);
  align-items: center;
  justify-content: space-between;
  gap: var(--gap-xl);
}

.searchIcon {
  width: 30px;
  padding-left: 5px;
  padding-top: 5px;
}
.uploadButton {
  display: block;
}
.plusIcon {
  width: 30px;
  padding-top: 2px;
}
.allTypeButton > span {
  display: block;
  border-radius: 15px; /* 배경의 모서리를 둥글게 */
  line-height: 26px !important;
  /* width: 26px !important; */
  padding: 2px 4px;
  border: 1px solid;
  margin-bottom: 2px;
  margin-right: 2px;
}
.bookmark > img {
  display: block;
  border-radius: 15px; /* 배경의 모서리를 둥글게 */
  line-height: 26px !important;
  /* width: 26px !important; */
  padding: 7px;
  border: 1px solid;
  margin-bottom: 2px;
  margin-right: 2px;
}
.typeIcon {
  padding: 5px; /* 배경과 글씨 사이의 여백 */
  margin: 0px 2px;
  border-radius: 15px; /* 배경의 모서리를 둥글게 */
  height: 15px !important;
  width: 15px !important;
  border: 1px solid;
}

/* 어두운 배경 */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* 어둡게 */
  z-index: 10;
}

/* 팝업 스타일 */
.popup {
  width: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 90%;
  max-height: 90%;
  padding: 20px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  z-index: 20;
  text-align: center;
  margin: auto;
  overflow-y: scroll;
}

.like-button.liked {
  background-color: white;
  color: #ff4500;
  border: 1px solid #ff4500;
}
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 24px;
}

.page-button {
  padding: 6px 12px;
  font-size: 14px;
  border: 1px solid #ddd;
  background-color: white;
  color: #333;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.page-button:hover {
  background-color: #f8f8f8;
  border-color: #aaa;
}

.page-button.active {
  background-color: #ff4500;
  color: white;
  border-color: #ff4500;
  font-weight: bold;
}

.page-button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}
@media (max-width: 1024px) {
  .popup {
    width: 90%;
  }
  .video-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  .video-title {
    width: 40vw;
  }
  .allTypeButton > span {
    font-size: 12px;
    width: 40px;
    padding: 0px;
  }
  .searchInput {
    height: 25px;
    width: 300px;
    font-size: 14px;
  }
}

@media screen and (max-width: 800px) {
  .searchInput {
    height: 20px;
    width: 200px;
    font-size: 14px;
  }
}
@media (max-width: 600px) {
  .video-grid {
    grid-template-columns: repeat(1, 1fr);
  }
  .video-title {
    width: 80vw;
  }
  .allTypeButton > span {
    font-size: 10px;
    padding: 0px;
  }
  .searchInput {
    font-size: 12px;
  }
}

@media screen and (max-width: 540px) {
  .searchInput {
    width: 150px;
  }
}
@media screen and (max-width: 430px) {
  .searchInput {
    width: 130px;
  }
}

@media screen and (max-width: 330px) {
  .searchInput {
    width: 100px;
  }
}

@media screen and (max-width: 290px) {
  .searchInput {
    width: 80px;
  }
}
</style>
