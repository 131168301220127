import { defineStore } from "pinia";

// 초기 상태 정의 (객체 복사를 위해 사용)
const initStore = {
  has_email: null, // boolean
  email_needs_agreement: null, // boolean
  is_email_valid: null, // boolean
  is_email_verified: null, // boolean
  email: null, // string
};

export const useUserStore = defineStore("user", {
  state: () => ({ ...initStore }), // 상태를 독립적으로 유지하도록 복사본 사용

  getters: {
    isLogin: (state) =>
      state.email !== null && state.email !== undefined && state.email !== "",
  },

  actions: {
    setUserData(userData) {
      this.$state.email = userData.email;
    },
    logOut() {
      this.$reset();
    },
  },
  persist: true, // 자동으로 localStorage에 저장됨
});
