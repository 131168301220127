<template>
  <div :class="$style.signUpContainer">
    <img
      :class="$style.signUpLogo"
      src="@/assets/images/logo_white.png"
      alt="Linkook logo"
    />
    <h2 :class="$style.signUpTitle">안녕하세요! 링꾹입니다.</h2>
    <p :class="$style.signUpDescription">
      링꾹을 이용하시려면 로그인이 필요합니다.
    </p>
    <button :class="$style.loginButton" @click="singin">
      <img src="@/assets/images/kakao_login.png" />
    </button>
  </div>
</template>
<script>
export default {
  name: "LinkookLiveSigninContent",
  methods: {
    singin() {
      window.Kakao.Auth.authorize({
        redirectUri: "https://live.linkook.it/kakaoLogin",
      });
    },
    getUserInfo() {
      window.Kakao.API.request({
        url: "/v2/user/me",
        success: (res) => {
          console.log("사용자 정보", res);
        },
        fail: (err) => {
          console.error("사용자 정보 가져오기 실패", err);
        },
      });
    },
  },
};
</script>
<style module>
.signUpContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4px;
  width: 100%;
  height: 80vh;
}
.signUpLogo {
  width: 50%;
  min-width: 300px;
  margin: 8px;
}
.signUpTitle {
  text-align: center;
  margin: 0;
}
.signUpDescription {
  text-align: center;
  font-size: 0.8rem;
}
.loginButton {
  margin: 16px;
}
</style>
