<template>
  <on-tiles></on-tiles>
</template>
<script>
// 1. sort
// 2. listing
import OnTiles from "@/components/on/OnTiles.vue";

export default {
  name: "LinkookLiveView",
  components: {
    OnTiles,
  },
};
</script>
